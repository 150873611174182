<template>
  <div class="view-wrap-index">
    <MyHeader :isHome="$route.name !== 'Home'" />
    <div class="view-router-box">
      <router-view></router-view>
    </div>
    <MyFooter />
  </div>
</template>

<script>
import MyHeader from "./comp/header.vue";
import MyFooter from "./comp/footer.vue";
export default {
  components: {
    MyHeader,
    MyFooter,
  },
  name: "WrapIndex",
  data() {
    return {};
  },
  created() {
    this.init();
  },
  methods: {
    init() {},
  },
};
</script>

<style lang="scss">
.view-router-box {
  margin-bottom: 2rem;
}
</style>
