<template>
  <div class="comp-header" :class="{ 'comp-header-home': isHome }">
    <div class="title-box flex-b w-100">
      <div class="header-info-left flex-s">
        <div class="title">Follow Us:</div>
        <div class="icon-list flex-s">
          <div
            @click="goPageOnClick(item)"
            v-for="item in iconList"
            :key="item.name"
            class="icon-item flex-c">
            <div class="iconfont" :class="item.name"></div>
          </div>
        </div>
      </div>
      <div class="header-info-right flex-s">
        <div class="email-box flex-s">
          <div class="icon">
            <div
              class="iconfont icon-youxiang-mail_fill"
              style="color: #ffb100; margin-right: 0.04rem"></div>
          </div>
          <div class="title flex-s">
            <div class="ttl">Email:</div>
            <div class="ttr">info@winofficekey.com</div>
          </div>
        </div>
        <div class="phone-box flex-s">
          <div class="icon">
            <div
              class="iconfont icon-headphoneserji"
              style="color: #ffb100; margin-right: 0.04rem"></div>
          </div>
          <div class="title flex-s">
            <div class="ttl">Call:</div>
            <div class="ttr">+(716) 732-9872</div>
          </div>
        </div>
      </div>
    </div>
    <div class="search-box flex-s w-100" :class="{ 'is-fixed': isHeaderFixed }">
      <div @click="$router.push('/home')" class="search-left point">
        <a onclick="return false;" href="/">
          <div class="img">
            <img
              class="h-100"
              src="../../../assets/images/New/logo-w.png"
              alt="" />
          </div>
        </a>
      </div>
      <div class="search-right flex-b">
        <div class="title-list-box">
          <div class="title-list flex-s">
            <div
              v-for="item in searchTitleList"
              @click="searchOnClick(item)"
              :key="item.name"
              class="title-item point">
              <a :href="item.path" onClick="return false;">{{ item.name }}</a>
            </div>
          </div>
        </div>
        <div class="btns-box flex-s point">
          <div
            class="icon"
            @mouseenter="setShowTopCart(true)"
            @mouseleave="setShowTopCart(false)"
            @click="showCartOnClick">
            <!-- <div class="iconfont icon-tuite1"></div> -->
            <svg class="cart-svg" fill="none" viewBox="0 0 70 75">
              <path
                class="cart-path"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                d="
				M14.973,26.021V15.296c0-1.109-0.865-2.292-1.922-2.628L1.49,8.99 M62.354,55.639c0,1.109-0.101,2.236-0.224,2.504
				c-0.123,0.268-1.684,0.487-2.793,0.487H17.989c-1.109,0-2.242-0.098-2.517-0.218c-0.275-0.12-0.5-1.664-0.5-2.773V23.273
				c0-1.109,0.101-2.236,0.224-2.504c0.123-0.268,1.684-0.487,2.793-0.487h41.348c1.109,0,2.242,0.098,2.517,0.218 c0.275,0.12,0.5,1.664,0.5,2.773V55.639z"></path>
              <line
                class="cart-line"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                x1="30.863"
                y1="20.74"
                x2="30.863"
                y2="58.63"></line>
              <line
                class="cart-line"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                x1="46.837"
                y1="20.74"
                x2="46.837"
                y2="58.63"></line>
              <line
                class="cart-line"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                x1="15.973"
                y1="33.308"
                x2="61.24"
                y2="33.308"></line>
              <line
                class="cart-line"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                x1="15.973"
                y1="46.285"
                x2="61.125"
                y2="46.285"></line>
              <circle
                class="cart-wheel"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                cx="23.442"
                cy="64.554"
                r="5.924"></circle>
              <circle
                class="cart-wheel"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                cx="53.314"
                cy="64.554"
                r="5.924"></circle>
            </svg>
            <div
              class="icon-float-box"
              :class="{ 'icon-float-box-show': showTopCart }">
              <div class="user-info GSAPheight">
                <div class="good-list">
                  <div
                    v-for="item in carList"
                    :key="item.id"
                    class="good-item flex-s">
                    <div class="img">
                      <img class="h100" :src="item?.goods?.image" alt="" />
                    </div>
                    <div class="right">
                      <div class="title">
                        {{ item?.goods?.title }}
                      </div>
                      <div class="price-box flex-b">
                        <div class="price" v-if="isVip">
                          ${{ item?.goods?.price }}
                        </div>
                        <div class="price" v-else>
                          ${{ item?.goods?.marketprice }}
                        </div>
                        <div class="num">x {{ item?.nums }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bottom-box">
                  <div class="header flex-b">
                    <div class="name">SUBTOTAL：</div>
                    <div class="price">$ {{ carTotalPrice }}</div>
                  </div>
                  <div class="btn-box flex-b">
                    <div @click="goToCarOnClick" class="btn1 flex-c">
                      VIEW CART
                    </div>
                    <div @click="goToCarWithBuyOnClick" class="btn1 flex-c">
                      CHECKOUT
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            @click="$router.push({ path: '/User', query: { TabIndex: 1 } })"
            v-if="isLogin"
            class="login-box flex-s">
            {{ userInfo.email }}
          </div>
          <div v-else class="login-box flex-s">
            <div @click="loginOnClick" class="login">Login</div>
            <div style="padding: 0 0.04rem">/</div>
            <div @click="rsgOnClick" class="register">Register</div>
          </div>
        </div>
      </div>
    </div>
    <van-overlay :show="rsgOverlayShow">
      <div class="header-overlay-common fixed-c">
        <div @click="rsgOverlayShow = false" class="close-btn point"></div>
        <div class="logo-box flex-c">
          <div class="logo">
            <img
              class="w100"
              src="../../../assets/images/New/logo-b.png"
              alt=""
              srcset="" />
          </div>
        </div>
        <div class="input-item">
          <div class="title">
            Username
            <span style="color: red; vertical-align: sub"> *</span>
          </div>
          <div class="input-box">
            <el-input
              v-model="rsgFormData.username"
              placeholder="input"></el-input>
          </div>
        </div>
        <div class="input-item">
          <div class="title">
            Email
            <span style="color: red; vertical-align: sub"> *</span>
          </div>
          <div class="input-box flex-s">
            <el-input
              v-model="rsgFormData.email"
              placeholder="input"></el-input>
            <div @click="sendEmailCode" class="send-btn flex-c point">
              {{ countdownTime > 0 ? `${countdownTime}S` : "Get Code " }}
            </div>
          </div>
        </div>
        <div class="input-item">
          <div class="title">
            Email verification code
            <span style="color: red; vertical-align: sub"> *</span>
          </div>
          <div class="input-box">
            <el-input
              v-model="rsgFormData.captcha"
              placeholder="input"></el-input>
          </div>
        </div>
        <div class="input-item">
          <div class="title">
            Password
            <span style="color: red; vertical-align: sub"> *</span>
          </div>
          <div class="input-box">
            <el-input
              type="password"
              v-model="rsgFormData.password"
              placeholder="input"></el-input>
          </div>
        </div>
        <div class="input-item">
          <div class="title">
            Confirm password
            <span style="color: red; vertical-align: sub"> *</span>
          </div>
          <div class="input-box">
            <el-input
              type="password"
              v-model="rsgFormData.secondPassword"
              placeholder="input"></el-input>
          </div>
        </div>
        <!-- <div class="input-item">
          <div class="title">Authentication Code（Optional）</div>
          <div class="input-box">
            <el-input
              v-model="rsgFormData.authCode"
              placeholder="input"></el-input>
          </div>
        </div> -->
        <!-- <div class="tips">Certified members can enjoy preferential rates.</div> -->
        <div class="rsg-btn flex-c point" @click="userRegOnClick">SUBMIT</div>
      </div>
    </van-overlay>
    <van-overlay :show="loginOverlayShow && !isMinDevice">
      <div class="header-overlay-common fixed-c">
        <div @click="loginOverlayShow = false" class="close-btn point"></div>
        <div class="logo-box flex-c">
          <div class="logo">
            <img
              class="w100"
              src="../../../assets/images/New/logo-b.png"
              alt=""
              srcset="" />
          </div>
        </div>
        <div class="input-item">
          <div class="title">Email address</div>
          <div class="input-box">
            <el-input
              v-model="loginFromData.account"
              placeholder="input"></el-input>
          </div>
        </div>
        <div class="input-item">
          <div class="title">Password</div>
          <div class="input-box">
            <el-input
              type="password"
              v-model="loginFromData.password"
              placeholder="input"></el-input>
          </div>
        </div>
        <div class="bottom-tip flex-s point">
          <div @click="remChecked = !remChecked" class="img">
            <div v-show="remChecked" class="in-img"></div>
          </div>
          <div @click="remChecked = !remChecked" class="remember">
            Remember me
          </div>
          <div @click="forgotOnClick" class="forgot point">
            Forgot Password？
          </div>
        </div>
        <div class="rsg-btn flex-c point" @click="userLoginOnClick">LOGIN</div>
        <div
          class="rsg-btn flex-c point"
          @click="
            loginOverlayShow = false;
            rsgOverlayShow = true;
          ">
          REGISTER
        </div>
        <!-- <div class="rsg-btn flex-c point" @click="loginOverlayShow = false">
          VISITOR BROWSING
        </div> -->
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import autoAnimate from "@formkit/auto-animate";
import {
  userRsgApi,
  sendEmailCodeApi,
  checkEmailCodeApi,
  userLoginApi,
  getNewdataApi,
  infoSubmitApi,
} from "@/network/api";
import { translate } from "@/utils/translate.js";
import { validateEmail } from "@/utils/common.js";
import { gsap } from "gsap";
import { encode, decode } from "js-base64";
import { throttle } from "lodash";
export default {
  name: "myheader",
  props: {
    isHome: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHeaderFixed: false,
      iconList: [
        {
          name: "icon-icon_facebook",
          href: "https://www.facebook.com/winofficekeycd",
        },
        {
          name: "icon-tuite2",
          href: "https://x.com/winofficekeys",
        },
        {
          name: "icon-skype",
          href: "https://join.skype.com/invite/r1gdfOyIwfVm",
        },
      ],
      searchTitleList: [
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Office",
          path: `/Office`,
        },
        {
          name: "Windows",
          path: `/Windows`,
        },
        {
          name: "Phone Key",
          path: `/PhoneKey`,
        },
        // {
        //   name: "Check-key",
        //   path: "/CheckKeys",
        // },
        // {
        //   name: "Reward",
        //   path: "/Roll",
        // },
        {
          name: "BlogList",
          path: "/BlogList",
        },
      ],
      // fuckInfoShow: false,
      remChecked: false,
      activeItemId: null,
      rsgOverlayShow: false,
      loginOverlayShow: false,
      showUserInfoFloat: false,
      showCarFloat: false,
      rsgFormData: {
        username: "",
        password: "",
        secondPassword: "",
        email: "",
        authCode: "",
        captcha: "",
      },
      loginFromData: {
        account: "",
        password: "",
      },
      show: false,
      keyframes: [
        { opacity: 1, transform: "rotate(0deg)" },
        { opacity: 1, transform: "rotate(180deg)" },
      ],
      countdownTime: 0,
      formData: {
        username: "",
        email: "",
        content: "",
      },
      Timer: null,
      Timer2: null,
      mbMeanShow: false,
      submitTimer: true,
    };
  },
  computed: {
    ...mapState([
      "isMinDevice",
      "userInfo",
      "homeGoods",
      "onlineUrl",
      "carList",
      "clickFuckInfoShow",
      "showTipPopup",
      "showTopCart",
    ]),
    ...mapGetters(["isVip", "isLogin", "userInfo"]),
    carTotalPrice() {
      let totle = 0;
      this.carList.forEach((item) => {
        if (this.isVip) {
          totle += item?.goods?.price * item?.nums;
        } else {
          totle += item?.goods?.marketprice * item?.nums;
        }
      });
      return totle?.toFixed(1);
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations([
      "setUserInfo",
      "setLanguage",
      "setToken",
      "setClickFuckInfoShow",
      "setShowTipPopup",
      "setShowTopCart",
    ]),
    ...mapActions(["getCartList", "setTokenSync"]),
    init() {
      addEventListener("scroll", () => {
        if (scrollY > 200) {
          this.isHeaderFixed = true;
        } else {
          this.isHeaderFixed = false;
        }
      });
      this.Timer2 = setTimeout(() => {
        if (!this.isLogin) {
          this.loginOverlayShow = true;
        }
      }, 3000);
      if (this.userInfo?.token) {
        this.getCartList();
      }
    },
    goPageOnClick(item) {
      console.log(333333, item);
      if (item.href) {
        window.open(item.href, "_blank");
      }
    },
    showCartOnClick() {
      if (this.showTopCart) {
        this.setShowTopCart(false);
      } else {
        this.setShowTopCart(true);
      }
    },
    searchOnClick(item) {
      this.$router.push(item.path);
    },
    goToCarOnClick() {
      if (!this.isLogin) {
        this.$message.error("Please login first");
        return false;
      }
      if (!this.carList?.length) {
        this.$message.error("The shopping cart is empty");
        return false;
      }
      this.$router.push("/Cart");
    },
    goToCarWithBuyOnClick(item) {
      if (!this.isLogin) {
        this.$message.error("Please login first");
        return false;
      }
      if (!this.carList?.length) {
        this.$message.error("The shopping cart is empty");
        return false;
      }
      this.$router.push({
        path: "/Cart",
        query: {
          carType: 2,
          buyList: JSON.stringify([item.id]),
        },
      });
    },
    forgotOnClick() {
      this.loginOverlayShow = false;
      this.$router.push("/resertPassword");
    },
    // forgotOnClick: throttle(function () {
    //   this.$message.warning("Please contact customer service");

    // }, 3000),
    // showMbMeanShowOnClick() {
    //   this.mbMeanShow = true;
    //   document.querySelectorAll(".MBGSAPheight").forEach((item) => {
    //     autoAnimate(item);
    //   });
    // },
    // closeOnClick() {
    //   this.setClickFuckInfoShow(false);
    // },
    // async submitOnClick() {
    //   const res = await infoSubmitApi(this.formData);
    //   this.$message.success(res.msg);
    //   this.setClickFuckInfoShow(false);
    // },
    //点击获取新闻数据跳转详情页
    // async gonew() {
    //   const res = await getNewdataApi(this.userInfo.id ? this.userInfo.id : "");
    //   if (res.code == 1) {
    //     let data = res.data.Items;
    //     this.$router.push({
    //       path: "/newDetail",
    //       query: { id: data[0].id, user_id: this.userInfo.id },
    //     });
    //   }
    // },
    JoinUsOnClick() {
      this.setClickFuckInfoShow(true);
    },
    // mouseenter1() {
    //   this.showCarFloat = true;
    //   // this.dhFun();
    // },
    // mouseleave1() {
    //   this.showCarFloat = false;
    // },
    // mouseenter2() {
    //   this.showUserInfoFloat = true;
    //   // this.dhFun();
    // },
    // mouseleave2() {
    //   this.showUserInfoFloat = false;
    // },
    // dhFun() {
    //   gsap.fromTo(
    //     ".GSAPheight",
    //     {
    //       height: "0px",
    //     },
    //     {
    //       height: "auto",
    //       duration: 0.3,
    //     }
    //   );
    // },
    // tabItemOnClick(item, isOpen = false) {
    //   if (isOpen) {
    //     item.open = !item.open;
    //     this.$forceUpdate();
    //   }
    //   if (item.title === "English") {
    //     translate.changeLanguage("english");
    //     this.$i18n.locale = "en";
    //     this.setLanguage("en");
    //     return false;
    //   }
    //   if (item.title === "Russian") {
    //     translate.changeLanguage("russian");
    //     this.$i18n.locale = "ru-RU";
    //     this.setLanguage("ru");
    //     return false;
    //   }
    //   if (item.parentId) {
    //     this.activeItemId = item.parentId;
    //   } else {
    //     if (!item.children.length) {
    //       this.activeItemId = item.id;
    //     }
    //   }
    //   if (item.path) {
    //     this.$router.push({
    //       path: item.path,
    //       query: {
    //         ...this.$router.query,
    //         ...(item.query || {}),
    //       },
    //     });
    //   }
    //   if (!isOpen) {
    //     this.mbMeanShow = false;
    //   }
    // },
    loginOnClick() {
      if (this.Timer2) {
        clearTimeout(this.Timer2);
      }
      this.loginOverlayShow = true;
      const remUser = localStorage.getItem("rsmNo");
      if (remUser) {
        const parseRemUserArray = remUser.split("&");
        if (parseRemUserArray.length > 1) {
          this.loginFromData.account = decode(parseRemUserArray[0]);
          this.loginFromData.password = decode(parseRemUserArray[1]);
          this.remChecked = true;
        }
      }
    },
    rsgOnClick() {
      if (this.Timer2) {
        clearTimeout(this.Timer2);
      }
      this.rsgOverlayShow = true;
    },
    async userLoginOnClick() {
      let flag = true;
      Object.keys(this.loginFromData).forEach((key) => {
        if (!this.loginFromData[key]) {
          if (flag) {
            this.$message.error("Please fill in the complete information");
          }
          flag = false;
        }
      });
      if (!flag) {
        return false;
      }

      const res = await userLoginApi(this.loginFromData);
      if (this.remChecked) {
        const user = encode(`${this.loginFromData.account}`);
        const pass = encode(`${this.loginFromData.password}`);
        localStorage.setItem("rsmNo", `${user}&${pass}`);
      } else {
        localStorage.setItem("rsmNo", "");
      }
      await this.setTokenSync(res?.data?.userinfo?.token);
      this.$message.success(res.msg);
      this.loginOverlayShow = false;
      setTimeout(() => {
        location.reload();
      }, 300);
    },
    async userRegOnClick() {
      if (!this.submitTimer) {
        return false;
      }
      this.submitTimer = false;
      setTimeout(() => {
        this.submitTimer = true;
      }, 3000);
      let flag = true;
      Object.keys(this.rsgFormData).forEach((key) => {
        if (key !== "authCode") {
          if (!this.rsgFormData[key]) {
            if (flag) {
              this.$message.error("Please fill in the complete information");
            }
            flag = false;
          }
        }
      });
      if (!flag) {
        return false;
      }
      if (this.rsgFormData.password !== this.rsgFormData.secondPassword) {
        this.$message.error("The two password entries are inconsistent");
        return false;
      }
      const isCheck = await this.checkEmailCode();
      if (!isCheck) {
        return false;
      }
      const params = this.rsgFormData;
      const res = await userRsgApi(params);
      // this.setToken(res?.data?.userinfo?.token);
      await this.setTokenSync(res?.data?.userinfo?.token);
      this.$message.success(res.msg);
      this.rsgOverlayShow = false;
    },
    async sendEmailCode() {
      if (this.countdownTime) {
        return false;
      }
      const email = this.rsgFormData.email;
      if (!validateEmail(email)) {
        this.$message.error("Email is not valid");
        return false;
      }
      const params = {
        email,
        event: "register",
      };
      if (this.Timer) {
        clearInterval(this.Timer);
      }
      const res = await sendEmailCodeApi(params);
      console.log(res);
      this.countdownTime = 60;
      this.Timer = setInterval(() => {
        if (this.countdownTime > 0) {
          this.countdownTime--;
        }
      }, 1000);
      this.$message.success(res.msg);
    },
    async checkEmailCode() {
      const params = {
        email: this.rsgFormData.email,
        event: "register",
        captcha: this.rsgFormData.captcha,
      };
      const res = await checkEmailCodeApi(params);
      return true;
    },
  },
};
</script>

<style lang="scss">
.comp-header {
  width: 100%;
  padding: 0rem 3.1rem;
  box-sizing: border-box;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0);
  color: #fff;
  position: relative;
  z-index: 2;
  font-weight: 300;
  font-size: 0.16rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 0.2rem;
  color: #ffffff;
  .header-overlay-common {
    width: 4.8rem;
    background: #fff;
    box-shadow: #eee 0rem 0rem 0.02rem 0.02rem;
    border-radius: 0.12rem;
    padding: 0.06rem 0.51rem 0.24rem;
    position: relative;

    .close-btn {
      position: absolute;
      right: 0.2rem;
      top: 0.2rem;
      width: 0.25rem;
      height: 0.25rem;

      @include pcurl("pc-home-icon10.png");
    }

    .logo-box {
      margin-bottom: 0.04rem;
      // background: rgb(0, 56, 121);
      .logo {
        width: 2.08rem;
      }
    }

    .input-item {
      margin-bottom: 5px;

      .title {
        font-size: 0.28rem;
        margin-bottom: 2px;
        color: #939393;
      }

      .input-box {
        width: 100%;
        height: 0.48rem;
        display: flex;
        align-items: flex-start;
        // margin-bottom: 2px;
        .send-btn {
          width: 2.04rem;
          // height: 0.48rem;
          height: 40px;
          background: var(--yellow);
          border-radius: 0.04rem;
          font-size: 0.28rem;
          vertical-align: bottom;
          color: #fff;
          // font-weight: 600;
          flex-shrink: 0;
        }
      }
    }

    .tips {
      font-size: 0.2rem;
      color: #888;
      margin-bottom: 15px;
    }

    .bottom-tip {
      margin-bottom: 0.28rem;

      .img {
        width: 0.24rem;
        height: 0.24rem;
        border: 1px solid #e5e5e5;
        margin-right: 0.13rem;
        padding: 0.02rem;
        box-sizing: border-box;

        // @include pcurl("pc-home-icon11.png");
        .in-img {
          width: 100%;
          height: 100%;
          background: rgba(136, 136, 136, 1);
        }
      }

      .remember {
        font-size: 0.2rem;
        color: #888;
      }

      .forgot {
        font-size: 0.22rem;
        margin-left: auto;
        color: #888;
      }
    }

    .rsg-btn {
      width: 100%;
      height: 0.41rem;
      background: var(--yellow);
      color: #fff;
      font-size: 0.22rem;
      margin-bottom: 0.12rem;
    }
  }
  .title-box {
    height: 0.52rem;
    margin-bottom: 0.15rem;
    .header-info-left {
      .title {
        margin-right: 0.1rem;
      }
      .icon-list {
        .icon-item {
          width: 0.34rem;
          height: 0.34rem;
          border: 0.01rem solid rgba(189, 189, 189, 0.35);
          border-radius: 50%;
          cursor: pointer;
          margin-right: 0.1rem;
          &:hover {
            background: #fff;
            color: #000;
          }
        }
      }
    }
    .header-info-right {
      .email-box {
        margin-right: 0.4rem;
      }
      .phone-box {
      }
    }
  }
  .search-box {
    height: 0.84rem;
    .search-left {
      height: 100%;
      margin-right: 0.64rem;
      .img {
        height: 100%;
      }
    }
    .search-right {
      // width: 100%;
      // height: 100%;
      background: #0e1422;
      border-radius: 41px 41px 41px 41px;
      border: 2px solid #909090;
      // border: 0.04rem solid rgba(189, 189, 189, 0.35);
      // border-radius: 1rem;
      padding: 0.1rem 0.24rem 0.1rem 0.36rem;
      .title-list-box {
        .title-list {
          .title-item {
            // margin: 0 0.1rem;
            margin-right: 0.28rem;
            color: #fff;
            &:hover {
              color: var(--yellow);
            }
          }
        }
      }
      .btns-box {
        .icon {
          width: 0.4rem;
          margin-right: 0.3rem;
          position: relative;
          .icon-float-box {
            position: absolute;
            width: 3rem;
            top: 0.7rem;
            right: 0;
            max-height: 0rem;
            overflow: hidden;
            transition: all 0.2s;
            .user-info {
              background: rgba(0, 56, 121, 0.75);
              font-size: 0.14rem;
              color: #fff;
              overflow: hidden;

              .good-list {
                max-height: 4rem;
                overflow-y: auto;
                padding: 0.12rem;

                .good-item {
                  display: flex;
                  align-content: space-between;
                  margin-bottom: 0.13rem;

                  .img {
                    // width: 0.74rem;
                    height: 0.89rem;
                    // @include pcurl("pc-header-icon2.png");
                    flex-shrink: 0;
                    margin-right: 0.1rem;
                  }

                  .right {
                    .title {
                      margin-bottom: 0.14rem;
                    }
                  }
                }
              }

              .bottom-box {
                padding: 0.08rem 0.12rem;
                box-sizing: border-box;
                background: rgba(0, 56, 121, 1);
                padding-bottom: 0.2rem;
                .header {
                  margin-bottom: 0.08rem;
                }

                .btn-box {
                  .btn1 {
                    width: 1.3rem;
                    height: 0.38rem;
                    background: rgba(0, 118, 255, 1);
                  }
                }
              }
            }
          }
          .icon-float-box-show {
            max-height: 6rem;
          }
          // &:hover {
          //   .icon-float-box {
          //     max-height: 6rem;
          //   }
          // }
        }
        .login-box {
          width: fit-content;
          padding: 0 0.1rem;
          height: 0.5rem;
          background: #ffb100;
          border-radius: 0.25rem;
          font-size: 0.16rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .is-fixed {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 1.24rem;
    padding: 0.18rem 3rem;
    box-sizing: border-box;
    background: #101a32;
  }
}
.comp-header-home {
  max-width: unset;
  padding: 0.04rem 2.6rem;
  height: 3rem;
  background: url("https://winofficekey.com/wp-content/uploads/2024/09/1-1.png");
  background-size: 100% 201%;
  background-position-y: 54%;
}
</style>
