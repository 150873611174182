<template>
  <div class="comp-footer">
    <div class="svg">
      <svg
        height="0.6rem"
        width="100%"
        class="svg--shape"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        viewBox="0 0 1440 150"
        fill="#fff">
        <path
          d="M 0 26.1978 C 275.76 83.8152 430.707 65.0509 716.279 25.6386 C 930.422 -3.86123 1210.32 -3.98357 1439 9.18045 C 2072.34 45.9691 2201.93 62.4429 2560 26.198 V 172.199 L 0 172.199 V 26.1978 Z">
          <animate
            repeatCount="indefinite"
            fill="freeze"
            attributeName="d"
            dur="10s"
            values="M0 25.9086C277 84.5821 433 65.736 720 25.9086C934.818 -3.9019 1214.06 -5.23669 1442 8.06597C2079 45.2421 2208 63.5007 2560 25.9088V171.91L0 171.91V25.9086Z; M0 86.3149C316 86.315 444 159.155 884 51.1554C1324 -56.8446 1320.29 34.1214 1538 70.4063C1814 116.407 2156 188.408 2560 86.315V232.317L0 232.316V86.3149Z; M0 53.6584C158 11.0001 213 0 363 0C513 0 855.555 115.001 1154 115.001C1440 115.001 1626 -38.0004 2560 53.6585V199.66L0 199.66V53.6584Z; M0 25.9086C277 84.5821 433 65.736 720 25.9086C934.818 -3.9019 1214.06 -5.23669 1442 8.06597C2079 45.2421 2208 63.5007 2560 25.9088V171.91L0 171.91V25.9086Z"></animate>
        </path>
      </svg>
    </div>
    <div class="main">
      <div class="col1">
        <div class="common-title">Join us</div>
        <div class="text-box" style="margin-bottom: 0.4rem">
          <!-- <div style="margin-bottom: 0.2rem">
            Professional Windows Key Agent
          </div> -->
          <div>
            Get all the latest information on sales and offers and stay up to
            date with our news. Sign up for newsletter
          </div>
        </div>
        <!-- <div class="get-btn point" style="margin-bottom: 0.4rem">
          Get a Quote
        </div> -->
        <div class="icon-list flex-s">
          <div
            @click="goPageOnClick(item)"
            class="icon-item flex-c"
            v-for="item in iconList"
            :key="item.name">
            <div class="iconfont" :class="item.name"></div>
          </div>
        </div>
      </div>
      <div class="col2">
        <div class="common-title">Company</div>
        <div class="col2-list">
          <div
            @click="goToPageOnClick(item)"
            v-for="item in companyTextList"
            :key="item.name"
            class="col2-item flex-s point">
            <div class="icon"></div>
            <div class="text">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="col2">
        <div class="common-title">Privacy</div>
        <div class="col2-list">
          <div
            @click="goToPageOnClick(item)"
            v-for="item in privacyTextList"
            :key="item.name"
            class="col2-item flex-s point">
            <div class="icon"></div>
            <div class="text">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="col4">
        <div class="common-title">Subscribe</div>
        <div class="form-box">
          <div class="form-item">
            <div class="form-item-label">
              Name<span style="margin-left: 0.04rem; color: red">*</span>
            </div>
            <div class="form-item-input">
              <el-input
                class="clear-style"
                v-model="name"
                placeholder="name"></el-input>
            </div>
          </div>
          <div class="form-item">
            <div class="form-item-label">
              Email<span style="margin-left: 0.04rem; color: red">*</span>
            </div>
            <div class="form-item-input">
              <el-input
                class="clear-style"
                v-model="email"
                placeholder="email"></el-input>
            </div>
          </div>
          <div @click="submitOnClick" class="form-submit flex-c">Submit</div>
        </div>
      </div>
    </div>
    <div class="bottom-box">
      <div class="text">Copyright ©2024 WinOfficeKey. All rights reserved.</div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
import { infoSubmitApi } from "@/network/api.js";
export default {
  name: "myfooter",
  data() {
    return {
      name: "",
      email: "",
      iconList: [
        {
          name: "icon-icon_facebook",
          href: "https://www.facebook.com/winofficekeycd",
        },
        {
          name: "icon-tuite2",
          href: "https://x.com/winofficekeys",
        },
        {
          name: "icon-skype",
          href: "https://join.skype.com/invite/r1gdfOyIwfVm",
        },
      ],
      companyTextList: [
        {
          name: "About Us",
          keywords: "About Us",
        },
        {
          name: "Contact Us",
          keywords: "Contact Us",
        },
        {
          name: "Check Key",
          keywords: "888",
        },
        {
          name: "Become our distributor now",
          keywords: "Become our distributor now",
        },
      ],
      privacyTextList: [
        {
          name: "Delivery Policy",
          keywords: "Delivery Policy",
        },
        {
          name: "Privacy Policy",
          keywords: "Privacy Policy",
        },
        {
          name: "After Sales Policy",
          keywords: "After Sales Policy",
        },
        {
          name: "Terms of Service",
          keywords: "Terms of Service",
        },
      ],
    };
  },
  methods: {
    goPageOnClick(item) {
      console.log(333333, item);
      if (item.href) {
        window.open(item.href, "_blank");
      }
    },
    goToPageOnClick(item) {
      if (item.keywords) {
        if (item.keywords === "888") {
          this.$router.push("/CheckKeys");
        } else {
          // this.$router.push(`/Blogdetail?id=${item.id}`);
          this.$router.push({
            path: `/Blog/${item.keywords}`,
            //  query: { id: v.id }
          });
        }
      }
    },
    async submitOnClick() {
      const params = {
        username: this.name,
        email: this.email,
        content: "from footer",
      };
      const res = await infoSubmitApi(params);
      this.$message.success(res.msg);
      this.name = "";
      this.email = "";
    },
  },
};
</script>

<style lang="scss">
.comp-footer {
  width: 100%;
  height: 5rem;
  background: #0f1421;
  color: #fff;
  margin-top: -2rem;
  .svg {
    width: 100%;
    height: 0.5rem;
    transform: rotate(180deg);
  }
  .main {
    padding-top: 0.5rem;
    padding-left: 2.4rem;
    display: flex;
    align-items: flex-start;
    .common-title {
      margin-bottom: 0.4rem;
      position: relative;
      // height: 0.6rem;
      font-size: 0.22rem;
    }
    .common-title::after {
      content: "";
      width: 0.56rem;
      height: 0.03rem;
      position: absolute;
      bottom: -0.16rem;
      left: 0;
      border-bottom: 0.04rem dashed #ffb100;
    }
    .col1 {
      width: 3rem;
      flex-shrink: 0;
      margin-right: 0.3rem;
      .text-box {
        font-weight: 300;
      }
      .get-btn {
        padding: 0.16rem 0.34rem;
        background: var(--yellow);
        color: #fff;
        width: fit-content;
        border-radius: 0.4rem;
      }
      .icon-list {
        .icon-item {
          width: 0.4rem;
          height: 0.4rem;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.3);
          color: rgba(255, 255, 255, 0.7);
          margin-right: 0.2rem;
          cursor: pointer;
          &:hover {
            background: #fff;
            color: #000;
          }
        }
      }
    }
    .col2 {
      width: 3rem;
      flex-shrink: 0;
      margin-right: 0.3rem;
      .col2-list {
        .col2-item {
          margin-bottom: 0.14rem;
          opacity: 0.75;
          padding-left: 0.14rem;
          position: relative;
          &::after {
            content: "";
            width: 0.06rem;
            height: 0.06rem;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            background: #ffb100;
          }
        }
      }
    }
    .col4 {
      .form-box {
        .form-item {
          margin-bottom: 0.1rem;
          .form-item-label {
            margin-bottom: 0.06rem;
          }
          .form-item-input {
            background: #fff;
            // border: 1px solid #ffb100;
            border-radius: 0.03rem;
          }
        }
        .form-submit {
          width: fit-content;
          padding: 0.1rem 0.2rem;
          background: #066aab;
          color: #fff;
          border-radius: 0.04rem;
          cursor: pointer;
        }
      }
    }
  }
  .bottom-box {
    width: 100%;
    height: 2rem;
    background: #0f1421;
    position: relative;
    .line {
      position: absolute;
      left: 0;
      bottom: 1rem;
      width: 100%;
      height: 1px;
      background: rgba(255, 255, 255, 0.3);
    }
    .text {
      position: absolute;
      left: 0;
      bottom: 0.3rem;
      width: 100%;
      text-align: center;
    }
  }
}
</style>
